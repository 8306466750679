<template>
  <v-container fluid>
    <v-row>
      <v-col class="custom-content-container pt-0" cols="12">
        <v-row>
          <v-col md="8" offset-md="2" class="gray-background">
            <div class="p-10">
              <table class="width-100">
             <!--    <pre>{{ engineer }}</pre> -->
                <tr>
                  <td class="pb-2 width-200px font-size-18 text-capitalize">
                    Address Line 1
                  </td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    <template v-if="engineer.address_line_1">{{ engineer.address_line_1 }}</template>
                    <span v-else class="text-muted">No Address Line 1</span>
                  <!--   {{ engineer.address_line_1 || " No Address Line 1 " }} -->
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 width-200px font-size-18 text-capitalize">
                    Address Line 2
                  </td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    <template v-if="engineer.address_line_2">{{ engineer.address_line_2 }}</template>
                    <span v-else class="text-muted">No Address Line 2</span>
                   <!--  {{ engineer.address_line_2 || " No Address Line 2 " }} -->
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 width-200px font-size-18 text-capitalize">
                    Unit no.
                  </td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    <template v-if="engineer.unit_number">{{ engineer.unit_number }}</template>
                    <span v-else class="text-muted">No Unit no</span>
                <!--     {{ engineer.unit_number || " No Unit no. " }} -->
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 width-200px font-size-18 text-capitalize">
                    Postal Code
                  </td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    <template v-if="engineer.postal_code">{{engineer.postal_code}}</template>
                    <span v-else class="text-muted">No Postal Code</span>
                   <!--  {{ engineer.postal_code || " No Postal Code " }} -->
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-18 text-capitalize">Country</td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    <template v-if="engineer.country">{{engineer.country}}</template>
                    <span v-else class="text-muted">No Country</span>
                   <!--  {{ engineer.country || " No Country " }} -->
                  </td>
                </tr>
              </table>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";

export default {
  name: "engineer-address",
  mixins: [CommonMixin],
  props: {
    engineer: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  computed: {
    /* getCountryName() {
      if (
        this.lodash.isEmpty(this.engineer) === false &&
        this.lodash.isEmpty(this.engineer.country) === false
      ) {
        return this.engineer.country.name;
      }
      return null;
    }, */
  },
};
</script>
