<template>
  <v-container fluid>
    <v-row>
      <v-col class="custom-content-container pt-0" cols="12">
        <v-row>
          <v-col md="8" offset-md="2" class="gray-background">
            <div class="p-10">
              <table class="width-100">
                <tr>
                  <td class="pb-2 width-200px font-size-18 text-capitalize">
                    Full name:
                  </td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    <template v-if="engineer.emergency_contact_name"> {{ engineer.emergency_contact_name }}</template>
                    <span v-else class="text-muted">no name</span>
                     
                  <!--   {{ engineer.emergency_contact_name || " no name " }} -->
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-18 text-capitalize">
                    Phone number:
                  </td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    <template v-if="engineer.emergency_contact_number">{{ engineer.emergency_contact_number }}</template>
                    <span v-else class="text-muted">no phone number</span>
                   <!--  {{ engineer.emergency_contact_number || " no phone number " }} -->
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-18 text-capitalize">Relation:</td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    <template v-if="engineer.emergency_contact_relation">{{ engineer.emergency_contact_relation }}</template>
                    <span v-else class="text-muted">no relation</span>
                  <!--   {{ engineer.emergency_contact_relation || " no relation " }} -->
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-18 text-capitalize">Email:</td>
                  <td class="pb-2 font-size-18 font-weight-600">
                    <template v-if="engineer.emergency_contact_email">{{ engineer.emergency_contact_email }}</template>
                    <span v-else class="text-muted">no email</span>
                    <!-- {{ engineer.emergency_contact_email || " no email " }} -->
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-18 text-capitalize d-flex">Address:</td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize" v-if="engineer.emergency_contact_street_1 || engineer.emergency_contact_street_2 || engineer.emergency_contact_unit_no || engineer.emergency_contact_postal_code">
                    
                    {{ engineer.emergency_contact_street_1 }}
                    {{ engineer.emergency_contact_street_2 }} <br>
                    {{ engineer.emergency_contact_unit_no }} 
                    {{ engineer.emergency_contact_postal_code }} 
                    {{ engineer.contact_country }}
                  </td>
                  <td class="text-muted pb-2 font-size-18 font-weight-600 text-capitalize" v-else>
                   no address
                  </td>
                </tr>
              </table>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";

export default {
  name: "emergency-contact",
  mixins: [CommonMixin],
  props: {
    engineer: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      fieldDescriptions: [],
    };
  },
};
</script>
