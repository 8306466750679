var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-form',{ref:"passwordForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('v-row',[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formData.engineer),expression:"formData.engineer",modifiers:{"trim":true}}],attrs:{"type":"hidden"},domProps:{"value":(_vm.formData.engineer)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "engineer", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","offset-md":"3"}},[_c('v-text-field',{attrs:{"append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show2 ? 'text' : 'password',"dense":"","filled":"","label":"New Password","solo":"","flat":"","color":"cyan","hint":"At least 8 characters","rules":[
           _vm.validateRules.required(_vm.formData.password, 'New Password'),
          ]},on:{"click:append":function($event){_vm.show2 = !_vm.show2},"keypress":(e) => _vm.manageLimit(e),"input":function($event){return _vm.validatePassword($event)}},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.password"}}),_c('p',{staticClass:"frmValidation",class:{ 'frmValidation--passed': _vm.has_min }},[_c('i',{staticClass:"frmIcon fas",class:_vm.has_min ? 'fa-check' : 'fa-times'}),_vm._v(" The password must be at least 8 character ")]),_c('p',{staticClass:"frmValidation",class:{ 'frmValidation--passed': _vm.has_uppercase }},[_c('i',{staticClass:"frmIcon fas",class:_vm.has_uppercase ? 'fa-check' : 'fa-times'}),_vm._v(" Has a capital letter ")]),_c('p',{staticClass:"frmValidation",class:{ 'frmValidation--passed': _vm.has_lowercase }},[_c('i',{staticClass:"frmIcon fas",class:_vm.has_lowercase ? 'fa-check' : 'fa-times'}),_vm._v(" Has a lowercase letter ")]),_c('p',{staticClass:"frmValidation",class:{ 'frmValidation--passed': _vm.has_number }},[_c('i',{staticClass:"frmIcon fas",class:_vm.has_number ? 'fa-check' : 'fa-times'}),_vm._v(" Has a number ")]),_c('p',{staticClass:"frmValidation",class:{ 'frmValidation--passed': _vm.has_special }},[_c('i',{staticClass:"frmIcon fas",class:_vm.has_special ? 'fa-check' : 'fa-times'}),_vm._v(" Has a special character ")])],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","offset-md":"3"}},[_c('v-text-field',{attrs:{"append-icon":_vm.show3 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show3 ? 'text' : 'password',"dense":"","filled":"","label":"Confirm Password","solo":"","flat":"","readonly":_vm.disabledDate(),"color":"cyan","hint":"At least 8 characters","rules":[
            _vm.validateRules.confirmPassword(
              _vm.formData.password_confirmation,
              'Confirm Password',
              _vm.formData.password
            ),
            _vm.validateRules.minLength(
              _vm.formData.password_confirmation,
              'Confirm Password',
              8
            ),
            _vm.validateRules.maxLength(
              _vm.formData.password_confirmation,
              'Confirm Password',
              16
            ),
          ]},on:{"click:append":function($event){_vm.show3 = !_vm.show3},"keypress":(e) => _vm.manageLimitCon(e)},model:{value:(_vm.formData.password_confirmation),callback:function ($$v) {_vm.$set(_vm.formData, "password_confirmation", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.password_confirmation"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"md":"6","offset-md":"3"}},[_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button text-white",attrs:{"disabled":!_vm.formValid || _vm.formLoading || _vm.disabledDate(),"loading":_vm.formLoading,"color":"cyan"},on:{"click":_vm.onSubmit}},[_vm._v(" Update ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }